@import "../../assets/styles/colors.scss";

.table {
  thead {
    tr {
      th {
        border-width: 1px;
        border-style: none !important;
        font-weight: normal !important;
        font-size: 14px;
        span {
          display: inline-flex;
          align-items: center;
          padding-inline-end: 5px;
          white-space: nowrap;
          text-transform: capitalize;
        }
      }
    }
  }
  tbody {
    margin-top: 4rem;
    td {
      border: none !important;
      word-wrap: break-word;
      width: 134px;
      max-width: 300px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      color: #546e7a;
      font-size: 14px;
    }
    tr {
      &:hover {
        box-shadow: 0px 6px 10px #00000017;
      }
    }
  }
}

.pagination {
  width: 75px;
  font-size: 12px;
}

.customDisable {
  opacity: 0.3 !important;
  border: none !important;
}
.pageNumber {
  width: 100px;
  margin: 0 25px;
}
