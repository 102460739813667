.app-drag-drop {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 20px;

  .draggable {
    height: 261px;
    width: 100%;
    border: 1px dashed #979797 !important;
    background-color: #eefcff !important;
    overflow: hidden;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    padding: 18px;
    flex-direction: column;
    &:hover,
    &:focus {
      box-shadow: 0 0 0.06rem 0.06rem #ccc;
    }
  }

  .remove-container {
    position: absolute;
    left: auto;
    right: -20px;
    top: -20px;
    z-index: 1111;
    cursor: pointer;
    .remove-image {
      width: 20px;
      height: 20px;
      background: red;
      padding: 5px;
      border-radius: 10px;
    }
  }
  .actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    .icon {
      display: inline-flex;
      width: 18px;
      margin: 0 10px;
      color: #ccc;
    }
  }

  .input-file-hidden {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
    cursor: pointer !important;
  }

  .image-clickable {
    z-index: 9;
    cursor: pointer;
  }

  .hand-preview-box {
    display: none;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 999;
    background: #fffefea3;
    cursor: pointer;
    .hand-preview {
      width: 20px;
      height: 20px;
    }
  }

  .image-preview {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 999;
    background: rgba(255, 255, 255, 0.89);
    height: 100vh;
    overflow: hidden;
    transition: all 0.1s;
    .image-box {
      max-width: 80vw;
      max-height: 80vh;
    }

    .iframe-box {
      max-width: 80vw;
      max-height: 80vh;
      width: 100%;
      height: 100%;
    }
  }

  .image-preview-header {
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    z-index: 999;
    padding: 25px;
    img {
      display: flex;
      width: 35px;
      height: 35px;
      cursor: pointer;
    }
  }

  .input-label {
    font-size: 14px;
    color: #6c757d !important;
    margin-bottom: 0.5rem !important;
  }

  .drag-drop-content-file {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    max-width: 136px;
    max-height: 136px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    &:hover {
      .hand-preview-box {
        display: flex;
      }
    }
  }

  .input-error-hint {
    color: red;
    font-size: 14px;
    margin: 10px 0;
  }

  .upload-helper {
    font-size: 12px;
    color: #6c757d !important;
    margin: 5px 0 !important;
  }
}
