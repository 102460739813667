.feedback__tooltip {
  position: relative;
  width: 250px;
  font-size: 50%;
  margin-top: 1rem;
  filter: drop-shadow(0px 0px 24px rgba(0, 0, 0, 0.25));
  
  & .inner__text {
    width: 100%;
    max-height: 175px;
    overflow-y: scroll;
  }

  &::before {
    content: "";
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-top: 0;
    border-bottom: 10px solid white;
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
}
