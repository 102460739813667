.notifications {
  position: relative;
}

.icon {
  width: 25px;
  height: 25px;
  cursor: pointer;
  position: relative;

  img {
    width: 100%;
    height: 100%;
  }

  .count {
    position: absolute;
    top: -8px;
    right: -7px;
    z-index: 99;
    font-size: 11px;
    background: #37a0f6;
    color: #fff;
    padding: 1px 5px;
    border-radius: 50%;
    min-width: 17px;
    display: flex;
    justify-content: center;
  }
}

.notificationList {
  display: none;
  width: 350px;
  position: absolute;
  top: 30px;
  left: auto;
  right: -30px;
  max-height: 400px;
  overflow-y: auto;
  flex-direction: column;
  background: #fff;
  box-shadow: 0 2px 4px 0 #2d415a4d;
  z-index: 999;
  border-radius: 5px;
  &.showList {
    display: flex;
  }
  &:empty {
    display: none !important;
  }

  .notificationItem {
    cursor: pointer;
    width: 100%;
    border-bottom: 1px solid #ccc;
    font-size: 14px;
    line-height: 1.5;
    padding: 10px 20px;

    &.unread {
      background: rgba(204, 204, 204, 0.45);
    }

    &:last-child {
      border-bottom: none;
    }
  }
}
