@import "../../assets/styles/colors.scss";

.filterBtn {
  font-size: 14px !important;
  padding: 10px !important;

  &:hover {
    border-bottom: 5px solid $primary;
  }
}

.subFilterBtn {
  @extend .filterBtn;

  &:hover {
    border-bottom-color: #262626;
  }
}

.active {
  border-bottom: 5px solid $primary !important;
}

.subActive {
  border-bottom: 5px solid #262626 !important;
}
