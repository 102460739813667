.chart {
  padding: 1.6rem;
  background: #ffffff;
  box-shadow: 0 1px 5px 0 rgba(63, 63, 68, 0.05);
  border-radius: 0.5rem;
  margin: 1rem 0;
  width: 100%;

  .chartHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .label {
      padding: 1rem;
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 1.9rem;
      color: #373f41;
      margin-bottom: 1.5rem;
      display: inline-flex;
      width: 100%;
    }
  }
}

[dir="rtl"] {
  .chart {
    .label {
      justify-content: flex-end;
    }
  }
}
