.calender-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.rdrMonth {
  margin: 28px 0 !important;
}
