@import "../../../../../assets/styles/colors.scss";

.filterBtn {
  font-size: 14px !important;
  padding: 10px !important;
  display: inline-flex;
  align-items: center;

  &:hover {
    border-bottom: 5px solid $primary;
  }
}

.active {
  border-bottom: 5px solid $primary !important;
}

.statistics {
  font-size: 14px;
}

.search {
  position: absolute;
  top: 25px;
  z-index: 10;
}

.exportOption {
  position: absolute;
  top: 0;
  width: 370px;
  min-height: 100vh;
  background-color: #fff;
  z-index: 1000;
}
