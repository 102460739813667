.card {
  width: 228px;
  height: 160px;
  padding: 28px 16px;
  border-radius: 5px;
  box-shadow: 0 1px 5px 0 rgba(63, 63, 68, 0.15);
  background-color: #fff;
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    flex-direction: column;
    width: 100%;

    .titleBox {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .titleContainer {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }

      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-inline-end: 9px;
        width: 22px;
        height: 22px;

        .iconImg {
          display: flex;
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .title {
        font-size: 11px;
        font-weight: bold;
        line-height: 1.45;
        letter-spacing: 0.91px;
        color: #66788a;
        text-transform: uppercase;
      }
    }

    .hint {
      opacity: 0.57;
      font-size: 12px;
      font-weight: 500;
      line-height: 1.33;
      letter-spacing: 1px;
      color: #66788a;
      min-height: 16px;
      display: inline-flex;
    }

    .percentage {
      font-size: 11px;
      font-weight: 500;
      color: currentcolor;
    }
  }

  .body {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 30px;
    font-weight: bold;
    line-height: 0.93;
    letter-spacing: -0.07px;
    color: #162a3d;
  }
}
.cardFetching {
  display: flex;
  justify-content: flex-start;
  grid-gap: 20px;
  gap: 20px;
  align-items: flex-start;
  width: 100%;

  .icon {
    animation: skeleton-loading 1.5s linear infinite alternate;
  }

  .title {
    animation: skeleton-loading 1.5s linear infinite alternate;
    display: flex;
    height: 22px;
    width: 100%;
  }

  .hint {
    animation: skeleton-loading 1.5s linear infinite alternate;
    display: flex;
    height: 20px;
    margin-top: 10px;
    opacity: 1 !important;
  }

  .body {
    animation: skeleton-loading 1.5s linear infinite alternate;
    margin-top: 10px;
  }
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }

  100% {
    background-color: hsl(200, 20%, 95%);
  }
}
