.search {
  position: absolute;
  top: 25px;
  z-index: 0;
}
.search_btn {
  position: absolute;
  top: 22px;
  right: 17px;
  height: 37%;
  display: flex;
  align-items: center;
}
.clear_btn {
  position: absolute;
  right: 87px;
  top: 30px;
  z-index: 10;
  cursor: pointer;
  width: 10px;
  opacity: 0.5;
}
