.app-auto-complete {
  position: relative;

  .autocomplete {
    min-height: 33px;
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.8rem;
    box-sizing: border-box;
    border-radius: 0.5rem;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    flex: 1 1;
    position: relative;
  }

  &.is-selected:not(.is-multiple) {
    .input-search {
      display: none;
    }
  }

  &.input-disabled {
    opacity: 0.6;
    pointer-events: none;
    .input-search {
      pointer-events: none;
    }
  }

  .selected-items {
    display: flex;
    padding: 3px 10px 3px 10px;
    flex: 1 1;
    overflow: hidden;
    align-items: center;
    max-width: 100% !important;
    height: auto !important;
    background: #fff;
    box-shadow: 0 0px 0px 1px #cfd8dc !important;
    border-radius: 2px;

    .selected-item {
      padding: 0 10px;
      display: inline-flex;
      font-size: 12px;
      white-space: pre-wrap;
      min-height: 15px;
      cursor: pointer;
      word-break: break-all;
      max-width: 90%;
      border-radius: 2px;
      background-color: #1898e316;
      margin: auto 0;
      margin-inline-end: 10px;
    }

    .selected-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #000;
      text-transform: capitalize;
    }

    .remove-me {
      width: 10px;
      margin-inline-start: 10px;
      opacity: 0.4;
    }
  }

  .input-search {
    border: none !important;
    width: auto;
    min-width: 10rem;
    font-weight: 400;
    font-size: 1.4rem;
    height: 15px !important;
    display: inline-flex;
    align-items: center;
    text-transform: capitalize;
    margin: 4px 0;
  }

  .list {
    opacity: 0;
    width: 0;
    transform: none;
    transition: opacity 12ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      transform 8ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: 0 0.5rem 0.5rem -0.3rem rgba(0, 0, 0, 0.02),
      0rem 0.8rem 1rem 0.1rem rgba(0, 0, 0, 0.02), 0rem 0.3rem 1.4rem 0.2rem rgba(0, 0, 0, 0.12);
    border-radius: 0.2rem;
    position: absolute;
    z-index: 99;
    top: 5rem;
    background: #ffff;
    overflow: hidden;

    .list-item {
      text-transform: capitalize;
      display: flex;
      width: 100%;
      overflow: hidden;
      box-sizing: border-box;
      cursor: pointer;
      line-height: 1.4;
      white-space: nowrap;
      padding: 0.6rem 1.5rem !important;
      height: 2.4rem !important;
      margin: 0 !important;
      font-size: 0.9rem !important;
      font-weight: 400;
      justify-content: flex-start;
      align-items: center;
      border-bottom: 1px solid #74777a14;

      &.active {
        background-color: rgba(0, 0, 0, 0.04);
      }

      &:hover,
      &.item-hover {
        text-decoration: none;
        background-color: rgba(24, 152, 227, 0.2) !important;
      }

      &:last-child {
        border-bottom-color: transparent;
      }
    }
  }

  .input-label {
    height: 2.7rem;
  }
  .autocomplete-label {
    margin-bottom: 0.5rem !important;
  }

  .drop-down-arrow {
    transform: rotate(0turn);
    position: absolute;
    right: 20px;
    top: 0;
    width: 12px;
    bottom: 0;
    margin: auto;
  }

  .fetch-loading {
    position: absolute;
    right: 16px;
    top: 0;
    margin: auto;
    bottom: 0;
    .loader {
      border: 0.2rem solid #f3f3f3;
      border-top: 0.2rem solid #068f55;
      border-right: 0.2rem solid #068f55;
      border-radius: 50%;
      width: 2rem;
      height: 2rem;
      animation: spin 0.5s linear infinite;
    }
  }

  &.active-list {
    .list {
      width: 100% !important;
      opacity: 1 !important;
      max-height: 18rem;
      overflow-y: auto;
    }

    .drop-down-arrow {
      transform: rotate(0.5turn);
    }
  }

  .close-icon {
    margin: auto;
    margin-inline-start: 1rem;
    color: var(--color-gray) !important;

    &:before {
      border-radius: 50%;
    }
  }
}

[dir="rtl"] {
  .select-input {
    .reset {
      right: auto !important;
      left: 3.8rem !important;
    }
  }

  .drop-down-arrow {
    left: 2.5rem;
    right: auto !important;
  }

  .fetch-loading {
    left: 0;
    right: auto !important;
  }
}
