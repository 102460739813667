@import "../../../assets/styles/colors.scss";

.wrapper {
  position: relative;
  width: 100%;
  min-height: 100vh;
  background-color: #f6f6f7;
}

.sideBar {
  position: relative;
  width: 280px;
  min-width: 280px;
  max-width: 23vw;
  min-height: 100vh;
  background-color: #f7f7f7;
  transition: 0.6s ease-in;

  button {
    transition: 0.6s ease-in;
    height: 45px;
  }
}

.collapseSideBar {
  @extend .sideBar;
  width: 82px;
  min-width: 82px;
  max-width: 82px;
}

.header {
  height: 47px;
  border-width: 2px !important;
}

.btnFont {
  font-size: 14px !important;
}

.overlay {
  position: absolute;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  background-color: #000;
  opacity: 0.48;
  z-index: 100;
}

.wrapperWidth {
  position: relative;
  width: calc(100vw - 280px);
  max-width: calc(100vw - 280px);
  transition: 0.6s ease-in;
}

.wrapperMaxWidth {
  @extend .wrapperWidth;
  width: calc(100vw - 82px);
  max-width: calc(100vw - 82px);
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.headerTitle {
  display: flex;
  width: 80%;
  align-items: center;
  justify-content: flex-start;
}
.headerAction {
  display: flex;
  width: 50%;
  align-items: center;
  justify-content: flex-end;
}
